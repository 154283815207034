import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import { AmortizationReportDto } from "@/models/interface/amortization-report";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class AmortizationReportService extends HttpClient {
  getAmortizationReportList(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<AmortizationReportDto>> {
    return this.get<Pagination<AmortizationReportDto>>(Api.AmortizationReport, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadReport(params?: RequestQueryParamsModel): Promise<Blob> {
    return this.get<Blob>(Api.AmortizationReportDownload, {
      params,
      responseType: "blob",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const amortizationReportService = new AmortizationReportService();
