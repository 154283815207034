var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_amortization_report") } },
    [
      _c(
        "a-form-model",
        {
          ref: "form",
          attrs: {
            model: _vm.formState,
            "wrapper-col": { span: 12 },
            "label-col": { span: 6 },
            "label-align": "left",
            rules: _vm.validationSchema,
            "data-testid": "amortization-report-form"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.handleSubmit.apply(null, arguments)
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_start_period"),
                        prop: "startPeriod"
                      }
                    },
                    [
                      _c("a-month-picker", {
                        attrs: {
                          "allow-clear": "",
                          format: _vm.DATE_FORMAT_MMM_YYYY,
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_start_period")
                          }),
                          "data-testid": "amortization-report-start-period"
                        },
                        model: {
                          value: _vm.formState.startPeriod,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "startPeriod", $$v)
                          },
                          expression: "formState.startPeriod"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_end_period"),
                        prop: "endPeriod"
                      }
                    },
                    [
                      _c("a-month-picker", {
                        attrs: {
                          "allow-clear": "",
                          format: _vm.DATE_FORMAT_MMM_YYYY,
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_end_period")
                          }),
                          "disabled-date": _vm.disabledEndPeriod,
                          "data-testid": "amortization-report-end-period"
                        },
                        model: {
                          value: _vm.formState.endPeriod,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "endPeriod", $$v)
                          },
                          expression: "formState.endPeriod"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: {
                        label: _vm.$t("lbl_transaction_type"),
                        prop: "transactionType"
                      }
                    },
                    [
                      _c("SelectAmortizationTransactionType", {
                        attrs: {
                          mode: "multiple",
                          "data-testid": "amortization-report-transaction-type"
                        },
                        model: {
                          value: _vm.formState.transactionType,
                          callback: function($$v) {
                            _vm.$set(_vm.formState, "transactionType", $$v)
                          },
                          expression: "formState.transactionType"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { "data-testid": "amortization-report-reset" },
                          on: { click: _vm.resetForm }
                        },
                        [_vm._v(_vm._s(_vm.$t("lbl_reset")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            "data-testid": "amortization-report-find",
                            type: "primary",
                            loading: _vm.loading.find,
                            "html-type": "submit"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { type: "flex", gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("a-table", {
                attrs: {
                  size: "small",
                  "row-key": "id",
                  "data-source": _vm.dtReport.data,
                  columns: _vm.columns,
                  loading: _vm.loading.find,
                  pagination: {
                    showTotal: function() {
                      return _vm.$t("lbl_total_items", {
                        total: _vm.dtReport.totalElements
                      })
                    },
                    total: _vm.dtReport.totalElements,
                    showSizeChanger: true,
                    pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
                    current: _vm.pagination.page,
                    pageSize: _vm.pagination.limit
                  },
                  scroll: _vm.tableSize,
                  "row-class-name": function(_record, index) {
                    return index % 2 ? "bg-white" : "bg-gray-light"
                  },
                  "data-testid": "amortization-report-table"
                },
                on: { change: _vm.onChangeTable },
                scopedSlots: _vm._u([
                  {
                    key: "cashOutNumber",
                    fn: function(text, record) {
                      return [
                        record.cashOutNumberSecureId
                          ? _c(
                              "RouterLink",
                              {
                                attrs: {
                                  to: {
                                    name: "cash-management.cash-in-out.edit",
                                    params: { id: record.cashOutNumberSecureId }
                                  },
                                  target: "_blank"
                                }
                              },
                              [_vm._v(" " + _vm._s(text) + " ")]
                            )
                          : _c("span", [_vm._v(_vm._s("-"))])
                      ]
                    }
                  },
                  {
                    key: "ellipsis",
                    fn: function(text) {
                      return [_c("VEllipsis", { attrs: { value: text } })]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _c(
                "a-button",
                {
                  attrs: {
                    type: "primary",
                    "data-testid": "amortization-report-download",
                    loading: _vm.loading.download
                  },
                  on: { click: _vm.handleDownload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }