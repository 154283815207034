

























import MNotification from "@/mixins/MNotification.vue";
import { FormModel } from "ant-design-vue";
import { Component, Mixins, Prop, Ref, Watch } from "vue-property-decorator";

@Component
export default class ModalSettingPrintInvoice extends Mixins(MNotification) {
  @Prop({ default: false }) readonly value!: boolean;
  @Ref("formModel") readonly formModel: FormModel | undefined;

  options = [
    { label: this.$t("lbl_gross_value"), value: "GROSS" },
    { label: this.$t("lbl_base_amount_dpp"), value: "TAX_BASE" },
  ];

  form: { useValueAs: string[] } = {
    useValueAs: [],
  };

  formRules = {
    useValueAs: [
      {
        required: true,
        message: this.$t("lbl_validation_required_error"),
      },
    ],
  };

  @Watch("value")
  onValueChange(val: boolean) {
    if (val) {
      this.form.useValueAs = [];
    }
  }

  handleCancel() {
    this.$emit("input", false);
    this.$emit("close");
  }

  async handleOk() {
    try {
      await this.formModel?.validate();

      const { useValueAs } = this.form;
      let payload!: "GROSS" | "TAX_BASE" | "ALL";

      if (useValueAs.length === 2) {
        payload = "ALL";
      } else if (useValueAs.includes("GROSS")) {
        payload = "GROSS";
      } else if (useValueAs.includes("TAX_BASE")) {
        payload = "TAX_BASE";
      }

      this.$emit("ok", { payload });
      this.$emit("input", false);
    } catch (error) {
      this.showNotifError("notif_validation_error");
    }
  }
}
