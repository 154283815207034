var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        title: _vm.$t("lbl_invoice_print_setting"),
        visible: _vm.value,
        "ok-text": _vm.$t("lbl_print"),
        "cancel-text": _vm.$t("lbl_close"),
        "destroy-on-close": ""
      },
      on: { cancel: _vm.handleCancel, ok: _vm.handleOk }
    },
    [
      _c(
        "a-form-model",
        {
          ref: "formModel",
          attrs: {
            model: _vm.form,
            rules: _vm.formRules,
            "wrapper-col": { span: 12 },
            "label-col": { span: 8 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-form-model-item",
            {
              attrs: { label: _vm.$t("lbl_use_value_as"), prop: "useValueAs" }
            },
            [
              _c("a-checkbox-group", {
                attrs: { options: _vm.options },
                model: {
                  value: _vm.form.useValueAs,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "useValueAs", $$v)
                  },
                  expression: "form.useValueAs"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }