var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        { attrs: { title: _vm.$t("lbl_invoice_ar_prepayment_detail") } },
        [
          _c(
            "fieldset",
            [
              _c("legend", [_vm._v(_vm._s(_vm.$t("lbl_general")))]),
              _c(
                "a-form-model",
                {
                  ref: "formModel",
                  attrs: {
                    model: _vm.store.detailDoc,
                    "wrapper-col": { span: 12 },
                    "label-col": { span: 8 },
                    "label-align": "left"
                  }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_invoice_number"),
                                prop: "documentNumber"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.documentNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "documentNumber",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.documentNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_return_number"),
                                prop: "returnNumber"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.returnNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "returnNumber",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.returnNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_branch"),
                                prop: "branchWarehouse"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.branchWarehouse,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "branchWarehouse",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.branchWarehouse"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_invoice_type"),
                                prop: "invoiceType"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.invoiceType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "invoiceType",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.invoiceType"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_customer_name"),
                                prop: "customerName"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.customerName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "customerName",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.customerName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_shipping_address"),
                                prop: "customerShipToAddress"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "extra",
                                  fn: function() {
                                    return [
                                      _c("character-length", {
                                        attrs: {
                                          value:
                                            _vm.store.detailDoc
                                              .customerShipToAddress
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("a-textarea", {
                                attrs: { disabled: "" },
                                model: {
                                  value:
                                    _vm.store.detailDoc.customerShipToAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "customerShipToAddress",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "store.detailDoc.customerShipToAddress"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_billing_address"),
                                prop: "customerBillToAddress"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "extra",
                                  fn: function() {
                                    return [
                                      _c("character-length", {
                                        attrs: {
                                          value:
                                            _vm.store.detailDoc
                                              .customerBillToAddress
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("a-textarea", {
                                attrs: { disabled: "" },
                                model: {
                                  value:
                                    _vm.store.detailDoc.customerBillToAddress,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "customerBillToAddress",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "store.detailDoc.customerBillToAddress"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_term_of_payment"),
                                prop: "termOfPayment"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.termOfPayment,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "termOfPayment",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.termOfPayment"
                                }
                              })
                            ],
                            1
                          ),
                          _vm.store.detailDoc.isLuxury !== null
                            ? _c(
                                "a-form-model-item",
                                {
                                  attrs: { label: _vm.$t("lbl_luxury_goods") }
                                },
                                [
                                  _c("DisplayBoolean", {
                                    attrs: {
                                      value: _vm.store.detailDoc.isLuxury,
                                      mode: "yes"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_invoice_date"),
                                prop: "invoiceDate",
                                "label-align": "right"
                              }
                            },
                            [
                              _c("a-date-picker", {
                                attrs: {
                                  format: _vm.DEFAULT_DATE_FORMAT,
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.store.detailDoc.invoiceDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "invoiceDate",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.invoiceDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_accounting_date"),
                                prop: "accountingDate",
                                "label-align": "right"
                              }
                            },
                            [
                              _c("a-date-picker", {
                                attrs: {
                                  format: _vm.DEFAULT_DATE_FORMAT,
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.store.detailDoc.accountingDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "accountingDate",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.accountingDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_currency"),
                                prop: "currency",
                                "label-align": "right"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.currency,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "currency",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.currency"
                                }
                              })
                            ],
                            1
                          ),
                          !_vm.isIdr
                            ? _c(
                                "a-form-model-item",
                                {
                                  attrs: {
                                    label: _vm.$t("lbl_currency_rates"),
                                    prop: "currencyRate",
                                    "label-align": "right"
                                  }
                                },
                                [
                                  _c("a-input-number", {
                                    staticClass: "w-50",
                                    attrs: {
                                      parser: _vm.reverseFormatNumber,
                                      formatter: _vm.formatterNumber,
                                      precision: _vm.storeBaseDecimalPlace,
                                      disabled: ""
                                    },
                                    model: {
                                      value: _vm.store.detailDoc.currencyRate,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.store.detailDoc,
                                          "currencyRate",
                                          _vm._n($$v)
                                        )
                                      },
                                      expression: "store.detailDoc.currencyRate"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_receivable_account"),
                                prop: "receivableAccount",
                                "label-align": "right"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.receivableAccount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "receivableAccount",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "store.detailDoc.receivableAccount"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_line_account"),
                                prop: "lineAccount",
                                "label-align": "right"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.lineAccount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "lineAccount",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.lineAccount"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_po_number"),
                                prop: "poNumber",
                                "label-align": "right"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.poNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "poNumber",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.poNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_invoice_description"),
                                prop: "description",
                                "label-align": "right"
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "extra",
                                  fn: function() {
                                    return [
                                      _c("character-length", {
                                        attrs: {
                                          value: _vm.store.detailDoc.description
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("a-textarea", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.description,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.description"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_status"),
                                prop: "invoicePrepaymentStatus",
                                "label-align": "right"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value:
                                    _vm.store.detailDoc.invoicePrepaymentStatus,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "invoicePrepaymentStatus",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "store.detailDoc.invoicePrepaymentStatus"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_journal"),
                                "label-align": "right"
                              }
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "generaljournal.detail",
                                      params: {
                                        id: _vm.store.detailDoc.journalId
                                      }
                                    }
                                  }
                                },
                                [
                                  _c("a-button", { attrs: { type: "link" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.store.detailDoc.journalNumber
                                        ) +
                                        " "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "fieldset",
            [
              _c("legend", [_vm._v(_vm._s(_vm.$t("lbl_tax")))]),
              _c(
                "a-form-model",
                {
                  attrs: {
                    "wrapper-col": { span: 12 },
                    "label-col": { span: 8 },
                    "label-align": "left"
                  }
                },
                [
                  _c(
                    "a-row",
                    { attrs: { gutter: [16, 16] } },
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_customer_tax_type"),
                                prop: "customerTaxType"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.customerTaxType,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "customerTaxType",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.customerTaxType"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_tax_registration_number"),
                                prop: "taxRegistrationNumber"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value:
                                    _vm.store.detailDoc.taxRegistrationNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "taxRegistrationNumber",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "store.detailDoc.taxRegistrationNumber"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_tax_registration_name"),
                                prop: "taxRegistrationName"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value:
                                    _vm.store.detailDoc.taxRegistrationName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "taxRegistrationName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "store.detailDoc.taxRegistrationName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "a-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_tax_invoice_date"),
                                prop: "taxInvoiceDate",
                                "label-align": "right"
                              }
                            },
                            [
                              _c("a-date-picker", {
                                attrs: {
                                  format: _vm.DEFAULT_DATE_FORMAT,
                                  disabled: ""
                                },
                                model: {
                                  value: _vm.store.detailDoc.taxInvoiceDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "taxInvoiceDate",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.taxInvoiceDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_tax_invoice_number"),
                                prop: "taxInvoiceNumber",
                                "label-align": "right"
                              }
                            },
                            [
                              _c("a-input", {
                                attrs: { disabled: "" },
                                model: {
                                  value: _vm.store.detailDoc.taxInvoiceNumber,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.store.detailDoc,
                                      "taxInvoiceNumber",
                                      $$v
                                    )
                                  },
                                  expression: "store.detailDoc.taxInvoiceNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "fieldset",
            [
              _c("legend", [_vm._v(_vm._s(_vm.$t("lbl_payment")))]),
              _c(
                "a-form-model",
                {
                  attrs: {
                    "wrapper-col": { span: 6 },
                    "label-col": { span: 4 },
                    "label-align": "left"
                  }
                },
                [
                  _c(
                    "a-form-model-item",
                    { attrs: { label: _vm.$t("lbl_amount"), prop: "amount" } },
                    [
                      _c("a-input-number", {
                        staticClass: "w-100",
                        attrs: {
                          parser: _vm.reverseFormatNumber,
                          formatter: _vm.formatterNumber,
                          precision: _vm.storeBaseDecimalPlace,
                          disabled: ""
                        },
                        model: {
                          value: _vm.store.detailDoc.amount,
                          callback: function($$v) {
                            _vm.$set(_vm.store.detailDoc, "amount", _vm._n($$v))
                          },
                          expression: "store.detailDoc.amount"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_tax_code"), prop: "taxCode" }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.store.detailDoc.taxCode,
                          callback: function($$v) {
                            _vm.$set(_vm.store.detailDoc, "taxCode", $$v)
                          },
                          expression: "store.detailDoc.taxCode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      attrs: { label: _vm.$t("lbl_tax_type"), prop: "taxType" }
                    },
                    [
                      _c("a-input", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.store.detailDoc.taxType,
                          callback: function($$v) {
                            _vm.$set(_vm.store.detailDoc, "taxType", $$v)
                          },
                          expression: "store.detailDoc.taxType"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.store.detailDoc.inclusiveTaxRateName
                    ? _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_inclusive_tax"),
                            prop: "taxType"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.store.detailDoc.inclusiveTaxRateName,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.store.detailDoc,
                                  "inclusiveTaxRateName",
                                  $$v
                                )
                              },
                              expression: "store.detailDoc.inclusiveTaxRateName"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c("a-col", { attrs: { span: 12 } }, [
                _c(
                  "fieldset",
                  [
                    _c("legend", [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_prepayment_used")) + " ")
                    ]),
                    _c("a-table", {
                      attrs: {
                        size: "small",
                        pagination: false,
                        scroll: { y: 600 },
                        "row-key": function(r, i) {
                          return i
                        },
                        "data-source": _vm.store.detailDoc.invoiceARDetails,
                        columns: _vm.columnPrepaymentUsed
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "nullable",
                          fn: function(text) {
                            return [_vm._v(_vm._s(text || "-"))]
                          }
                        },
                        {
                          key: "date",
                          fn: function(text) {
                            return [_vm._v(_vm._s(_vm._f("date")(text)))]
                          }
                        },
                        {
                          key: "currency",
                          fn: function(text) {
                            return [_vm._v(_vm._s(_vm._f("currency")(text)))]
                          }
                        },
                        {
                          key: "footer",
                          fn: function() {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("lbl_total_used_amount")) +
                                    " : " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.store.detailDoc.prepaymentUsed
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    }),
                    _c("div", { staticClass: "text-right mt-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("lbl_total_items", {
                              total: _vm.totalItemUsed
                            })
                          ) +
                          " "
                      )
                    ])
                  ],
                  1
                )
              ]),
              _c("a-col", { attrs: { span: 12 } }, [
                _c(
                  "fieldset",
                  [
                    _c("legend", [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_prepayment_paid")) + " ")
                    ]),
                    _c("a-table", {
                      attrs: {
                        size: "small",
                        pagination: false,
                        scroll: { y: 600 },
                        "row-key": function(r, i) {
                          return i
                        },
                        "data-source":
                          _vm.store.detailDoc.invoiceARReceiptDetails,
                        columns: _vm.columnPrepaymentPaid
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "nullable",
                          fn: function(text) {
                            return [_vm._v(_vm._s(text || "-"))]
                          }
                        },
                        {
                          key: "date",
                          fn: function(text) {
                            return [_vm._v(_vm._s(_vm._f("date")(text)))]
                          }
                        },
                        {
                          key: "currency",
                          fn: function(text) {
                            return [_vm._v(_vm._s(_vm._f("currency")(text)))]
                          }
                        },
                        {
                          key: "footer",
                          fn: function() {
                            return [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("lbl_total_receipt_amount")) +
                                    " : " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.store.detailDoc.prepaymentPaid
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            ]
                          },
                          proxy: true
                        }
                      ])
                    }),
                    _c("div", { staticClass: "text-right mt-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("lbl_total_items", {
                              total: _vm.totalItemPaid
                            })
                          ) +
                          " "
                      )
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c("a-divider"),
          _c("DisplayTotal", {
            attrs: {
              "sub-total": _vm.store.detailDoc.subTotal,
              total: _vm.store.detailDoc.total,
              "total-tax": _vm.store.detailDoc.totalTax,
              "base-amount": _vm.store.detailDoc.baseAmount
            }
          }),
          _c("a-divider"),
          _c(
            "div",
            { staticClass: "mt-2 text-right" },
            [
              _c(
                "a-space",
                [
                  _c("a-button", { on: { click: _vm.handleBack } }, [
                    _vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")
                  ]),
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary", loading: _vm.loading.print },
                      on: { click: _vm.handleOpenModalSettingPrint }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
                  ),
                  _vm.$can("cancel", "invoice-prepayment") && _vm.allowCancel
                    ? [
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: _vm.$t("pop_confirmation"),
                              "ok-text": _vm.$t("lbl_yes"),
                              "cancel-text": _vm.$t("lbl_no")
                            },
                            on: { confirm: _vm.handleCancel }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "danger",
                                  loading: _vm.loading.cancel
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      ),
      _c("ModalSettingPrintInvoice", {
        on: { ok: _vm.handlePrint },
        model: {
          value: _vm.showModalSettingPrint,
          callback: function($$v) {
            _vm.showModalSettingPrint = $$v
          },
          expression: "showModalSettingPrint"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }