











import Vue from "vue";
export default Vue.extend({
  name: "InputSearch",
  methods: {
    onSearch(value) {
      this.$emit("input-search", value);
    },
  },
});
