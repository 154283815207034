var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-input-search", {
        attrs: {
          placeholder: _vm.$t("lbl_input_search_placeholder"),
          "enter-button": "",
          "allow-clear": ""
        },
        on: { search: _vm.onSearch }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }