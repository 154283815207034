





























































































































import SelectAmortizationTransactionType from "@/components/custom/select/SelectAmortizationTransactionType.vue";
import VEllipsis from "@/components/VEllipsis/VEllipsis.vue";
import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { setNumbering } from "@/helpers/common";
import { useMaxOneYear, useMinByDate } from "@/hooks/datepicker";
import { APagination } from "@/hooks/table";
import useBlob from "@/hooks/useBlob";
import useDate from "@/hooks/useDate";
import MNotification from "@/mixins/MNotification.vue";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { Pagination } from "@/models/constant/interface/common.interface";
import { DATE_FORMAT_MMM_YYYY } from "@/models/constants/date.constant";
import {
  AmortizationReportDto,
  AmortizationReportParams,
  AmortizationReportParamsDownload,
} from "@/models/interface/amortization-report";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { amortizationReportService } from "@/services/amortization-report.service";
import { ColumnDef, SorterProps } from "@/types";
import { FormUtils } from "@/utils/FormUtils";
import { FormModel } from "ant-design-vue";
import moment, { Moment } from "moment";
import { Component, Mixins, Ref } from "vue-property-decorator";

type FormState = {
  transactionType: string[];
  startPeriod: Moment | null;
  endPeriod: Moment | null;
};

@Component({
  components: {
    SelectAmortizationTransactionType,
    VEllipsis,
  },
})
export default class AmortizationReport extends Mixins(MNotification) {
  @Ref()
  readonly form!: FormModel;

  DATE_FORMAT_MMM_YYYY = DATE_FORMAT_MMM_YYYY;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;
  dtReport: Pagination<AmortizationReportDto & Record<string, any>> = {
    data: [],
    totalElements: 0,
    currentPage: 0,
    totalPages: 0,
  };
  formState: FormState = {
    transactionType: [],
    startPeriod: null,
    endPeriod: null,
  };
  loading = {
    find: false,
    download: false,
  };
  pagination = {
    page: 1,
    limit: 10,
    sorts: undefined as string | undefined,
  };
  columns = this.buildStaticColumns();
  validationSchema = {
    startPeriod: FormUtils.mandatory(),
    endPeriod: FormUtils.mandatory(),
  };

  get tableSize() {
    const n = this.columns.length;
    return {
      x: `calc(100% + ${n <= 18 ? 3500 : n * 30}px)`,
    };
  }

  async getAmortizationReportList(
    params?: RequestQueryParamsModel
  ): Promise<void> {
    try {
      this.loading.find = true;
      const response =
        await amortizationReportService.getAmortizationReportList(params);
      this.dtReport.data = [];
      response.data.forEach((item, index) => {
        const periodData: Record<string, number> = {};
        item.details.forEach((period, j) => {
          periodData[`accumulation${j}`] = period.accumulationAmount;
          periodData[`amortization${j}`] = period.amortizationAmount;
          periodData[`bookValue${j}`] = period.bookValue;
        });
        this.dtReport.data.push({
          ...item,
          no: setNumbering(params?.page, params?.limit, index),
          ...periodData,
        });
      });
      this.dtReport.totalElements = response.totalElements;
      this.updateColumns();
    } catch {
      this.dtReport.data = [];
      this.dtReport.totalElements = 0;
    } finally {
      this.loading.find = false;
    }
  }

  updateColumns(): void {
    this.columns = [...this.buildStaticColumns(), ...this.buildPeriodColumns()];
  }

  buildStaticColumns(): ColumnDef[] {
    return [
      {
        title: "#",
        dataIndex: "no",
        width: 50,
      },
      {
        title: this.$t("lbl_transaction_type"),
        dataIndex: "transactionType",
        key: "transactionType",
        width: 250,
        sorter: true,
      },
      {
        title: this.$t("lbl_unit_code"),
        dataIndex: "unitCode",
        key: "unitCode",
        sorter: true,
        width: 150,
      },
      {
        title: this.$t("lbl_leasing_contract_number"),
        dataIndex: "leasingContractNumber",
        sorter: true,
        key: "leasingContractNumber",
        width: 250,
        customRender: text => text ?? "-",
      },
      {
        title: this.$t("lbl_serial_number"),
        dataIndex: "serialNumber",
        width: 200,
        sorter: true,
        key: "serialNumber",
        customRender: text => text ?? "-",
      },
      {
        title: this.$t("lbl_acquisition_date"),
        dataIndex: "acquisitionDate",
        sorter: true,
        key: "acquisitionDate",
        width: 150,
        customRender: text => dateFormat(text),
      },
      {
        title: this.$t("lbl_brand"),
        dataIndex: "brand",
        key: "brand",
        width: 100,
        customRender: text => text ?? "-",
      },
      {
        title: this.$t("lbl_type"),
        dataIndex: "type",
        key: "type",
        width: 100,
        customRender: text => text ?? "-",
      },
      {
        title: this.$t("lbl_lessor_name"),
        dataIndex: "lessorName",
        sorter: true,
        key: "lessorName",
        ellipsis: true,
        width: 250,
        scopedSlots: { customRender: "ellipsis" },
      },
      {
        title: this.$t("lbl_document_number"),
        dataIndex: "documentNumber",
        key: "documentNumber",
        width: 200,
        sorter: true,
      },
      {
        title: this.$t("lbl_year"),
        dataIndex: "year",
        key: "year",
        width: 50,
      },
      {
        title: this.$t("lbl_start_period"),
        dataIndex: "startPeriod",
        key: "startPeriod",
        width: 100,
        customRender: text => dateFormat(text, DATE_FORMAT_MMM_YYYY),
      },
      {
        title: this.$t("lbl_end_period"),
        dataIndex: "endPeriod",
        key: "endPeriod",
        width: 100,
        customRender: text => dateFormat(text, DATE_FORMAT_MMM_YYYY),
      },
      {
        title: this.$t("lbl_amount"),
        dataIndex: "amount",
        key: "amount",
        sorter: true,
        width: 200,
        align: "right",
        customRender: text => currencyFilter(text),
      },
      {
        title: this.$t("lbl_transaction_date"),
        dataIndex: "transactionDate",
        key: "transactionDate",
        width: 190,
        sorter: true,
        customRender: text => dateFormat(text),
      },
      {
        title: this.$t("lbl_cash_out_number"),
        dataIndex: "cashOutNumber",
        key: "cashOutNumber",
        sorter: true,
        width: 190,
        scopedSlots: { customRender: "cashOutNumber" },
      },
      {
        title: this.$t("lbl_closing_date"),
        dataIndex: "closeDate",
        key: "closeDate",
        width: 150,
        sorter: true,
        customRender: text => dateFormat(text),
      },
      {
        title: this.$t("lbl_closing_note"),
        dataIndex: "closingNote",
        key: "closingNote",
        ellipsis: true,
        width: 200,
        scopedSlots: { customRender: "ellipsis" },
      },
    ];
  }

  buildPeriodColumns(): ColumnDef[] {
    const columns: any[] = [];
    const startPeriod = this.formState.startPeriod;
    const endPeriod = this.formState.endPeriod;
    const diffMonths = moment(endPeriod).diff(startPeriod, "months", true);

    for (let i = 0; i <= diffMonths; i++) {
      let month = moment(startPeriod);
      if (i > 0) {
        month = moment(startPeriod).add(i, "months");
      }
      columns.push({
        title: month.format(DATE_FORMAT_MMM_YYYY),
        children: [
          {
            title: this.$t("lbl_amortization"),
            dataIndex: `amortization${i}`,
            width: 200,
            align: "right",
            customRender: (text: number) => currencyFilter(text),
          },
          {
            title: this.$t("lbl_accumulation"),
            dataIndex: `accumulation${i}`,
            width: 200,
            align: "right",
            customRender: (text: number) => currencyFilter(text),
          },
          {
            title: this.$t("lbl_book_value"),
            dataIndex: `bookValue${i}`,
            width: 200,
            align: "right",
            customRender: (text: number) => currencyFilter(text),
          },
        ],
      });
    }

    return columns;
  }

  handleSubmit(): void {
    this.form.validate((valid: boolean) => {
      if (!valid) return;
      this.pagination.page = 1;
      const params = this.buildParamsList({
        state: this.formState,
        limit: this.pagination.limit,
        sorts: this.sorts,
      });
      this.getAmortizationReportList(params);
    });
  }

  disabledEndPeriod(curr: Moment): boolean {
    if (!this.formState.startPeriod) return false;
    const max = moment(this.formState.startPeriod)
      .add(11, "months")
      .endOf("days");
    return (
      useMaxOneYear(curr, max) || useMinByDate(curr, this.formState.startPeriod)
    );
  }

  resetForm(): void {
    this.form.resetFields();
  }

  buildParamsList({
    state,
    page = 1,
    limit = 10,
    sorts,
  }: {
    state: FormState;
    page?: number;
    limit?: number;
    sorts?: string;
  }): AmortizationReportParams {
    const { toStartDay, toEndDay } = useDate();
    const params: AmortizationReportParams = {
      limit,
      sorts,
      page: page - 1,
    };
    if (state.startPeriod) {
      params.startPeriod = toStartDay(state.startPeriod).format();
    }

    if (state.endPeriod) {
      params.endPeriod = toEndDay(state.endPeriod).format();
    }

    if (state.transactionType.length > 0) {
      params.transactionTypes = state.transactionType.join(",");
    }
    return params;
  }

  buildParamsDownload(
    state: FormState,
    totalElements: number
  ): AmortizationReportParamsDownload {
    const { transactionType, startPeriod, endPeriod } = state;

    const header = [
      this.$store.state.authStore.authData.companyName ||
        "PT. SATRIA PIRANTI PERKASA",
      startPeriod ? moment(startPeriod).format(DATE_FORMAT_MMM_YYYY) : "ALL",
      endPeriod ? moment(endPeriod).format(DATE_FORMAT_MMM_YYYY) : "ALL",
      transactionType.length > 0 ? transactionType.join(";") : "ALL",
    ];
    const params: AmortizationReportParamsDownload = {
      ...this.buildParamsList({
        state: state,
        limit: totalElements,
      }),
      params: header.join(","),
    };
    return params;
  }

  async handleDownload(): Promise<void> {
    try {
      const { toDownload } = useBlob();
      const params = this.buildParamsDownload(
        this.formState,
        this.dtReport.totalElements
      );
      this.loading.download = true;
      const response = await amortizationReportService.downloadReport(params);
      toDownload(response, "amortization-report.xlsx");
    } catch (error) {
      this.showNotifError("notif_download_error");
    } finally {
      this.loading.download = false;
    }
  }

  onChangeTable(
    { current, pageSize }: APagination,
    _,
    { columnKey, order }: SorterProps
  ): void {
    const staticSorts = "amortizationId:asc,startPeriod:asc";
    this.pagination.page = pageSize !== this.pagination.limit ? 1 : current;
    this.pagination.limit = pageSize;

    if (order === "ascend") {
      this.pagination.sorts = `${columnKey}:asc,${staticSorts}`;
    } else if (order === "descend") {
      this.pagination.sorts = `${columnKey}:desc,${staticSorts}`;
    } else {
      this.pagination.sorts = undefined;
    }

    const params = this.buildParamsList({
      state: this.formState,
      page: this.pagination.page,
      limit: this.pagination.limit,
      sorts: this.pagination.sorts,
    });
    this.getAmortizationReportList(params);
  }
}
